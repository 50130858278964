import { MenuItem } from '@mui/material';
import * as XLSX from 'xlsx-js-style';
import {
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from '@mui/x-data-grid';
import { useGetHelpInfo } from 'src/apis/indexAPI';
import { GridApiPro, GridCellParams, GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { MutableRefObject } from 'react';
import { format } from 'date-fns';

interface IExcelConfig {
  ignoreColumns: string[];
  fileName: string;
  sheetName: string;
  titleName: string;
  valueParser: {
    [key: string]: (value: number) => string;
  };
}

function getExcelData(
  apiRef: MutableRefObject<GridApiPro>,
  valueParser: Record<string, (value: number) => string>,
) {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  return filteredSortedRowIds.map((id: GridRowId) => {
    const row: Record<string, any> = {};
    visibleColumnsField.forEach((field: string) => {
      const cellParams: GridCellParams = apiRef.current.getCellParams(id, field);
      const { value } = cellParams;
      const parser = valueParser[field];
      row[field] = parser ? parser(Number(value)) : value;
    });
    return row;
  });
}

function applyStyles(worksheet: XLSX.WorkSheet) {
  const styledWorksheet = { ...worksheet };

  // Define the style for all cells with font size 10
  const cellStyle = { font: { sz: 10 } };

  const range = XLSX.utils.decode_range(styledWorksheet['!ref'] ?? '');

  // Apply the style to all cells
  for (let R = range.s.r; R <= range.e.r; R += 1) {
    for (let C = range.s.c; C <= range.e.c; C += 1) {
      const cellAddress = { c: C, r: R };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      if (styledWorksheet[cellRef]) {
        if (!styledWorksheet[cellRef].s) {
          styledWorksheet[cellRef].s = {};
        }
        styledWorksheet[cellRef].s = { ...styledWorksheet[cellRef].s, ...cellStyle };
      }
    }
  }

  // Define the bold style for headers
  const headerStyle = { font: { bold: true, sz: 10 } };

  // Apply the bold style to header cells
  for (let C = range.s.c; C <= range.e.c; C += 1) {
    const cellAddress = { c: C, r: 3 }; // Header row is the forth row (r: 3)
    const cellRef = XLSX.utils.encode_cell(cellAddress);
    if (styledWorksheet[cellRef]) {
      if (!styledWorksheet[cellRef].s) {
        styledWorksheet[cellRef].s = {};
      }
      styledWorksheet[cellRef].s = headerStyle;
    }
  }

  // Define the bold style for the first row with font size 12
  const firstRowStyle = { font: { bold: true, sz: 12 } };

  // Apply the bold style to the first row cells
  for (let C = range.s.c; C <= range.e.c; C += 1) {
    const cellAddress = { c: C, r: 0 }; // First row is the first row (r: 0)
    const cellRef = XLSX.utils.encode_cell(cellAddress);
    if (styledWorksheet[cellRef]) {
      if (!styledWorksheet[cellRef].s) {
        styledWorksheet[cellRef].s = {};
      }
      styledWorksheet[cellRef].s = firstRowStyle;
    }
  }

  return styledWorksheet;
}

function handleExport(
  apiRef: MutableRefObject<GridApiPro>,
  columns: GridColDef[],
  config: IExcelConfig,
  version: string = '',
) {
  const { ignoreColumns = [], fileName, sheetName, valueParser, titleName } = config;

  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
  const filteredIgnoreColumns = visibleColumnsField.filter((c) => !ignoreColumns.includes(c));
  const filteredColumns = columns.filter((c) => filteredIgnoreColumns.includes(c.field));

  const data = getExcelData(apiRef, valueParser);

  const columnNames = filteredColumns.map((c) => c.headerName);
  const fields = filteredColumns.map((c) => c.field);

  const rows = data.map((row) => {
    const mRow: Record<string, any> = fields.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {} as Record<string, any>);
    return mRow;
  });

  const worksheet = XLSX.utils.aoa_to_sheet([]);

  // Add title to A1
  XLSX.utils.sheet_add_aoa(worksheet, [[titleName]], { origin: 'A1' });

  // Add column headers starting from A4
  XLSX.utils.sheet_add_aoa(worksheet, [columnNames], { origin: 'A4' });

  // // Add data rows starting from A5
  XLSX.utils.sheet_add_json(worksheet, rows, {
    origin: 'A5',
    skipHeader: true,
  });

  XLSX.utils.sheet_add_aoa(
    worksheet,
    [[''], [''], [`TimeLog ${version}, ${format(Date.now(), 'dd-MM-yyyy')}`]],
    { origin: -1 },
  );

  // Calculate column widths
  const colWidths = fields.map((field, index) => {
    const maxLength = Math.max(
      columnNames[index]?.length ?? 0,
      ...rows.map((row) => (row[field] ? row[field].toString().length : 0)),
    );
    return { wch: maxLength };
  });

  worksheet['!cols'] = colWidths;

  // Apply styles to the worksheet
  const styledWorksheet = applyStyles(worksheet);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, styledWorksheet, sheetName);
  XLSX.writeFile(workbook, fileName, { compression: true });
}

export const GridExcelExportMenuItem = ({
  hideMenu,
  columns,
  config,
}: {
  hideMenu?: () => void;
  columns: GridColDef[];
  config: IExcelConfig;
}) => {
  const apiRef: MutableRefObject<GridApiPro> = useGridApiContext();
  const {
    helpInfo: { version },
  } = useGetHelpInfo();

  return (
    <MenuItem
      onClick={() => {
        handleExport(apiRef, columns, config, version);
        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Download as Excel
    </MenuItem>
  );
};
