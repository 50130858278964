import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IExchangeRatePeriodsResponse, IExchangeRatePeriod } from '../exchangeRateAPI';

const EXCHANGE_RATE_GET_PERIODS_URL_PATH = '/api/v1/exchange-rate/periods';
export const EXCHANGE_RATE_GET_PERIODS_MSW_STATUS_KEY = 'exchangeRatePeriodsAPI';
export const EXCHANGE_RATE_GET_PERIODS_KEY = 'EXCHANGE_RATE_GET_PERIODS';

const getPeriods = (): Promise<IExchangeRatePeriodsResponse> =>
  getFetch({
    path: EXCHANGE_RATE_GET_PERIODS_URL_PATH,
    key: EXCHANGE_RATE_GET_PERIODS_MSW_STATUS_KEY,
  });

export const useGetExchangeRatePeriods = () => {
  const { data, ...rest } = useQuery([EXCHANGE_RATE_GET_PERIODS_KEY], getPeriods);

  const exchangeRatePeriods: IExchangeRatePeriod[] = data?.entities
    ? data.entities.map(({ properties }) =>
        properties
          ? {
              ...properties,
            }
          : {
              intercompanyExchangeRatePeriodId: 0,
              periodStartDate: Date.now().toString(),
              periodEndDate: Date.now().toString(),
              isPeriodLocked: true,
              previousPeriodId: 0,
              nextPeriodId: 0,
            },
      )
    : [];

  return {
    exchangeRatePeriods,
    ...rest,
  };
};
