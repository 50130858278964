import { add, format, sub } from 'date-fns';
import { TTimeDuration } from './dateType';

interface IGetTimeRegistrationAccuracy {
  startDate: string;
  endDate: string;
}

export const getStartAndEndDateRange = (
  timeRegisterType: TTimeDuration,
): IGetTimeRegistrationAccuracy => {
  const today = format(Date.now(), 'yyyy-MM-dd');

  switch (timeRegisterType) {
    case 'weekly':
      return {
        startDate: format(sub(Date.now(), { days: 6 }), 'yyyy-MM-dd'),
        endDate: today,
      };
    case 'biWeekly':
      return {
        startDate: format(sub(Date.now(), { days: 13 }), 'yyyy-MM-dd'),
        endDate: today,
      };
    case 'monthly':
      return {
        startDate: format(sub(Date.now(), { days: 29 }), 'yyyy-MM-dd'),
        endDate: today,
      };
    default:
      return {
        startDate: format(add(Date.now(), { days: 7 }), 'yyyy-MM-dd'),
        endDate: today,
      };
  }
};
