import { add, format, formatISO, Locale, parseISO, startOfWeek } from 'date-fns';
import { da, de, enGB, fi, fr, nb, nl, ru, sv } from 'date-fns/locale';
import { Language } from 'src/apis/types/userSettingsAPI';
import { TDateFormats } from 'src/components/ui-components';

export const getYearMonthDateFromDateString = (date?: string) => {
  const parsedDate = date ? parseISO(date) : parseISO(formatISO(Date.now()));
  return format(parsedDate, 'yyyy-MM-dd');
};

export const getFirstDayOfWeek = (d?: Date) => {
  const date = d || Date.now();
  return format(
    startOfWeek(date, {
      weekStartsOn: 1,
    }),
    'yyyy-MM-dd',
  );
};

export const getDateOneWeekFromStartOfWeek = (d?: Date) => {
  const startOfWeekDate = getFirstDayOfWeek(d || new Date(Date.now()));

  return getYearMonthDateFromDateString(
    format(add(parseISO(startOfWeekDate), { days: 6 }), 'yyyy-MM-dd'),
  );
};

export const getDateTwoWeeksFromStartOfWeek = (d?: Date) => {
  const startOfWeekDate = getFirstDayOfWeek(d || new Date(Date.now()));

  return getYearMonthDateFromDateString(
    format(add(parseISO(startOfWeekDate), { days: 13 }), 'yyyy-MM-dd'),
  );
};

export const getDateThirtyDaysFromNow = (d?: string) => {
  const today = d ?? new Date(Date.now()).toISOString();

  return getYearMonthDateFromDateString(format(add(parseISO(today), { days: 29 }), 'yyyy-MM-dd'));
};

export const convertDateToLocaleString = (date: Date, locale: string) => {
  const dateString = date.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // In TimeLog, da-DK format follows dd-mm-yyyy instead of the standard dd.mm.yyyy.
  if (locale !== 'da-DK') {
    return dateString;
  }

  return dateString.replace(/\./g, '-');
};

export type TShortString = Language['shortString'];

export const localeDateFormat = (lang: TShortString): TDateFormats => {
  switch (lang) {
    case 'en':
      return 'dd/MM/yyyy';

    case 'ru':
    case 'nb':
    case 'de':
    case 'fi':
      return 'dd.MM.yyyy';

    case 'sv':
      return 'yyyy-MM-dd';

    default:
      return 'dd-MM-yyyy';
  }
};

export const getApiDateFormat = (date: Date): string => format(date, 'yyyy-MM-dd');

export const getLanguageFromSiteLocale = (siteLocale: string = 'da-DK'): TShortString => {
  const siteLanguage =
    siteLocale && siteLocale.length > 2 ? siteLocale.substring(0, siteLocale.indexOf('-')) : 'da';

  return siteLanguage as TShortString;
};
type LanguageShortString = Language['shortString'] | undefined;
export const getDateLocaleFromUserLanguage = (userLanguage: LanguageShortString): Locale => {
  switch (userLanguage) {
    case 'da':
      return da;
    case 'de':
      return de;
    case 'fi':
      return fi;
    case 'fr':
      return fr;
    case 'nb':
      return nb;
    case 'nl':
      return nl;
    case 'ru':
      return ru;
    case 'sv':
      return sv;
    default:
      return enGB;
  }
};

export const getDateStringFromSiteLocale = (date: Date, siteLocale: string): string => {
  const siteLanguage = getLanguageFromSiteLocale(siteLocale);
  const locale = localeDateFormat(siteLanguage);

  if (String(new Date(date)) === 'Invalid Date') {
    return '';
  }

  return format(new Date(date), locale);
};

export const getDateWithZeroOffsetFromDateString = (dateString: string | undefined | null) => {
  // expected date string is yyyyMMddT00:00:00
  let tmpDateStr = '';

  if (!dateString || dateString === undefined || dateString === null) {
    return new Date();
  }

  tmpDateStr = dateString.substring(0, dateString.indexOf('T'));

  const [year, month, day] = tmpDateStr.split('-');

  const monthString = month.length === 1 ? `0${month}` : month;
  const dayString = day.length === 1 ? `0${day}` : day;

  const date = new Date(`${year}-${monthString}-${dayString}T00:00:00`);

  return date;
};

// DRO: Only returns the day and month number form without the year. For eg. 13-08
export const convertDateToShortDateLocaleString = (date: Date, locale: string) => {
  const dateString = date.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
  });

  // In TimeLog, da-DK format follows dd-mm-yyyy instead of the standard dd.mm.yyyy.
  if (locale !== 'da-DK') {
    return dateString;
  }

  return dateString.replace(/\./g, '-');
};
