import ITimeTracker from 'src/apis/types/timeTrackerAPI';
import { findIana } from 'windows-iana';
import { toDate } from 'date-fns-tz';
import { differenceInMilliseconds, formatISO } from 'date-fns';

export const parseServerTimezone = (serverTimeZoneId?: string): string =>
  serverTimeZoneId ? findIana(serverTimeZoneId)[0] : 'Europe/Copenhagen';

export const calculateStartTimeStringInMin = (
  startTime: string,
  serverTimeZoneId?: string,
): number => {
  const zone = parseServerTimezone(serverTimeZoneId);
  const start = toDate(startTime, { timeZone: zone });
  const diffInMilliSeconds = Math.abs(differenceInMilliseconds(Date.now(), start));
  return Math.floor(diffInMilliSeconds / 1000 / 60) || 0;
};

export const getHourAndMinFromTimeTrackerResult = (
  timeTrackerResult: ITimeTracker | undefined | null,
  serverTimeZoneId?: string,
) => {
  if (timeTrackerResult) {
    const {
      minute = 0,
      timeRegistration,
      startTime = formatISO(Date.now()),
    } = timeTrackerResult?.properties || {};

    const totalMin =
      (timeRegistration?.minutes || 0) +
      minute +
      calculateStartTimeStringInMin(startTime, serverTimeZoneId);

    const hour: number = Math.trunc(totalMin / 60);
    const min: number = totalMin - hour * 60;

    return {
      hour: hour % 24, // make use is not more than 1 day
      min,
    };
  }
  return undefined;
};
