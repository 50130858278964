import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IBillablePercentage, IBillablePercentageItem } from 'src/apis/types/billablePercentageAPI';
import { IInformation } from 'src/screens/Home/HomeType';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { format } from 'date-fns';

const BILLABLE_PERCENTAGE_URL_PATH = '/api/v2/widget-data/billable-percentage';
export const GET_BILLABLE_PERCENTAGE_MSW_STATUS_KEY = 'getBillablePercentageAPI';
export const GET_BILLABLE_PERCENTAGE_KEY = 'GET_BILLABLE_PERCENTAGE_KEY';

const getBillablePercentageUrl = () => {
  const today = format(Date.now(), 'yyyy-MM-dd');
  return `${BILLABLE_PERCENTAGE_URL_PATH}?currentDate=${today}`;
};

export const getBillablePercentageUrlForTest = getBillablePercentageUrl;

const getWidgetInformationList = (
  {
    currentMonthToDateBillablePercentage,
    lastMonthBillablePercentage,
    averageHourlyRate,
    currencyAbb,
    currencyName,
  }: Omit<IBillablePercentageItem, 'userId'>,
  locale: string,
): Array<IInformation> => [
  {
    value: currentMonthToDateBillablePercentage.toString(),
    key: 'ThisMonth',
    trending: currentMonthToDateBillablePercentage >= lastMonthBillablePercentage ? 'Yes' : 'No',
  },
  { value: lastMonthBillablePercentage.toString(), key: 'PreviousMonth' },
  {
    value: `${averageHourlyRate.toLocaleString(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })} ${currencyAbb}`,
    key: 'AvgHourlyRate',
    abbrTitle: currencyName,
    abbrText: currencyAbb,
  },
];

export const getWidgetInformationListForTest = getWidgetInformationList;

const getBillablePercentage = (): Promise<IBillablePercentage> =>
  getFetch({
    path: getBillablePercentageUrl(),
    key: GET_BILLABLE_PERCENTAGE_MSW_STATUS_KEY,
  });

export const useGetBillablePercentageForHomeWidget = () => {
  const { data, ...restProps } = useQuery([GET_BILLABLE_PERCENTAGE_KEY], () =>
    getBillablePercentage(),
  );

  const locale = useGetLocale();

  if (data?.properties) {
    return {
      informationList: getWidgetInformationList(data.properties, locale),
      ...restProps,
    };
  }
  return {
    informationList: [],
    ...restProps,
  };
};
