import { Box, FormHelperText } from '@mui/material';
import {
  DesktopDatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  DesktopDateRangePicker as MuiDateRangePicker,
  DateRangePickerProps as MuiDateRangePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { forwardRef } from 'react';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { getLanguageFromSiteLocale, localeDateFormat } from 'src/utils/date';
import { useGetDateLocale } from 'src/utils/locale/dateLocale';
import { Typography } from '../Typography';

export const DatePicker = forwardRef<HTMLDivElement, MuiDatePickerProps<Date>>(
  ({ ...rest }, ref) => {
    const siteLocale = useGetLocale();
    const dateLocale = useGetDateLocale();
    const siteLanguage = getLanguageFromSiteLocale(siteLocale);
    return (
      <LocalizationProvider adapterLocale={dateLocale} dateAdapter={AdapterDateFns}>
        <MuiDatePicker format={localeDateFormat(siteLanguage)} {...rest} ref={ref} />
      </LocalizationProvider>
    );
  },
);

interface IDateRangePicker extends MuiDateRangePickerProps<Date> {
  error?: boolean;
  helperText?: string[];
}

export const DateRangePicker = forwardRef<HTMLDivElement, IDateRangePicker>(
  ({ error, helperText, ...rest }, ref) => {
    const siteLocale = useGetLocale();
    const dateLocale = useGetDateLocale();
    const siteLanguage = getLanguageFromSiteLocale(siteLocale);
    return (
      <Box>
        <LocalizationProvider adapterLocale={dateLocale} dateAdapter={AdapterDateFns}>
          <MuiDateRangePicker format={localeDateFormat(siteLanguage)} {...rest} ref={ref} />
          {helperText?.length ? (
            <FormHelperText error={error} sx={{ mt: 0.5, mx: 1.75 }}>
              {helperText.map((text) => (
                <Typography
                  component="span"
                  fontSize="inherit"
                  key={text}
                  sx={{ display: 'block' }}
                >
                  {text}
                </Typography>
              ))}
            </FormHelperText>
          ) : null}
        </LocalizationProvider>
      </Box>
    );
  },
);
