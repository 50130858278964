export default {
  Entities: [
    {
      Properties: {
        ActionName: 'TogglePeriodLock',
        ActionLink: 'http://localhost/tlp/api/v1/exchange-rate/toggle-period-lock',
        ActionMethod: 'PUT',
        MetaData: [],
      },
    },
    {
      Properties: {
        ActionName: 'CreateNewManualPeriod',
        ActionLink: 'http://localhost/tlp/api/v1/exchange-rate/manual-period',
        ActionMethod: 'POST',
        MetaData: [
          {
            Name: 'StartDate',
            DataType: 'DateTime',
            DefaultValue: '2024-08-01T00:00:00',
            IsImmutable: true,
          },
          {
            Name: 'EndDate',
            DataType: 'DateTime',
            DefaultValue: '',
            IsImmutable: false,
          },
        ],
      },
    },
    {
      Properties: {
        ActionName: 'DeletePeriod',
        ActionLink: 'http://localhost/tlp/api/v1/exchange-rate/period',
        ActionMethod: 'DELETE',
        MetaData: [],
      },
    },
    {
      Properties: {
        ActionName: 'ResetExchangeRates',
        ActionLink: 'http://localhost/tlp/api/v1/exchange-rate/reset-exchange-rates',
        ActionMethod: 'PUT',
        MetaData: [],
      },
    },
    {
      Properties: {
        ActionName: 'SetupAutomation',
        ActionLink: 'http://localhost/tlp/api/v1/exchange-rate/automation-settings',
        ActionMethod: 'PUT',
        MetaData: [
          {
            Name: 'MatrixMode',
            DataType: 'String',
            DefaultValue: 'Manual',
            IsImmutable: false,
          },
          {
            Name: 'NewPeriodStartMonthYear',
            DataType: 'String',
            DefaultValue: 'Apr 2022',
            IsImmutable: true,
          },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v1/exchange-rate/get-available-matrix-actions',
      Rel: 'self',
    },
  ],
};
