import { format, getMonth, getYear, isEqual, lastDayOfMonth, Locale, startOfMonth } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Tooltip } from 'src/components/mui-components';
import { Autocomplete } from 'src/components/mui-components/Autocomplete/Autocomplete';
import { stringToPascal } from 'src/utils/string';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { AutocompleteRenderInputParams } from '@mui/material';

import styles from './MonthPicker.module.scss';
import { MonthPickerDates, MonthPickerOption } from './MonthPicker.types';

export interface MonthPickerProps {
  monthRange: MonthPickerDates;
  setMonthRange?: (d: MonthPickerDates) => void;
  endYear?: number;
  startYear?: number;
  width?: number;
  userLocale: Locale;
  onApply?: () => void;
}

export const MonthPicker = ({
  monthRange,
  endYear,
  startYear,
  width,
  userLocale,
  setMonthRange,
  onApply,
}: MonthPickerProps) => {
  const [from, setFrom] = useState<Date>(startOfMonth(monthRange.from));
  const [to, setTo] = useState<Date>(lastDayOfMonth(monthRange.to));
  const [fromMonth, setFromMonth] = useState<string | undefined>((getMonth(from) + 1).toString());
  const [fromYear, setFromYear] = useState<string | undefined>(getYear(from).toString());
  const [toMonth, setToMonth] = useState<string | undefined>((getMonth(to) + 1).toString());
  const [toYear, setToYear] = useState<string | undefined>(getYear(to).toString());
  const [applyButtonDisabled, setApplyButtonDisabled] = useState<boolean>(true);
  const [initialFrom] = useState<Date>(from);
  const [initialTo] = useState<Date>(to);

  const { t } = useTranslation('monthPicker');

  useEffect(() => {
    setMonthRange?.({ from: startOfMonth(from), to: lastDayOfMonth(to) });
  }, [from, to, setMonthRange]);

  useEffect(() => {
    setApplyButtonDisabled(isEqual(initialFrom, from) && isEqual(initialTo, to));
  }, [from, to, initialFrom, initialTo]);

  const iconSize = '1rem';

  const componentProps = {
    paper: {
      sx: {
        minWidth: 100,
      },
    },
  };

  const getMonthLabel = useCallback(
    (m: string) => stringToPascal(format(new Date(`${m}/1/1980`), 'LLL', { locale: userLocale })),
    [userLocale],
  );

  const monthList: MonthPickerOption[] = useMemo(
    () =>
      Array.from(Array(12)).map((_, i) => {
        const n = (i + 1).toString();

        return {
          label: getMonthLabel(n),
          value: n,
        };
      }),
    [getMonthLabel],
  );

  const yearList: MonthPickerOption[] = useMemo(() => {
    const endPoint = endYear || new Date().getFullYear() + 50;
    const startPoint = startYear || new Date().getFullYear() - 50;
    const years = [];
    let count = endPoint;

    while (count >= startPoint) {
      years.push(count);
      count -= 1;
    }

    return years
      .map((y) => ({
        label: String(y),
        value: String(y),
      }))
      .reverse();
  }, [endYear, startYear]);

  const getMonthValue = useCallback(
    (m: string): MonthPickerOption => ({
      label: m ? getMonthLabel(m) : '',
      value: m || '',
    }),
    [getMonthLabel],
  );

  const getYearValue = useCallback(
    (m: string): MonthPickerOption => ({
      label: m || '',
      value: m || '',
    }),
    [],
  );

  const renderInput = (
    params: AutocompleteRenderInputParams,
    placeholder: string,
    label: string,
  ) => (
    <div ref={params.InputProps.ref}>
      <input
        {...params.inputProps}
        className={styles.TextField}
        placeholder={placeholder}
        aria-label={label}
      />
    </div>
  );

  const isOptionEqualToValue = (o: MonthPickerOption, v: MonthPickerOption) => o.value === v.value;

  const fromMonthDisabled = (o: MonthPickerOption) => {
    if (!toMonth || !toYear || Number(fromYear) < Number(toYear)) {
      return false;
    }

    return Number(o.value) > Number(toMonth);
  };

  const fromYearDisabled = (o: MonthPickerOption) => {
    if (!toYear) {
      return false;
    }

    if (Number(fromMonth) > Number(toMonth)) {
      return Number(o.value) >= Number(toYear);
    }

    return Number(o.value) > Number(toYear);
  };

  const toMonthDisabled = (o: MonthPickerOption) => {
    if (!fromMonth || !fromYear || !toYear) {
      return false;
    }

    if (Number(fromYear) < Number(toYear)) {
      return false;
    }

    return Number(o.value) < Number(fromMonth);
  };

  const toYearDisabled = (o: MonthPickerOption) => {
    if (!fromYear) {
      return false;
    }

    if (Number(fromMonth) > Number(toMonth)) {
      return Number(o.value) <= Number(fromYear);
    }

    return Number(o.value) < Number(fromYear);
  };

  const handleFromMonthChange = ({ v }: { v: MonthPickerOption | null }) => {
    setFromMonth(v?.value);
    setFrom(startOfMonth(new Date(`${v?.value}/1/${fromYear}`)));
  };
  const handleFromYearChange = ({ v }: { v: MonthPickerOption | null }) => {
    setFromYear(v?.value);
    setFrom(startOfMonth(new Date(`${fromMonth}/1/${v?.value}`)));
  };
  const handleToMonthChange = ({ v }: { v: MonthPickerOption | null }) => {
    setToMonth(v?.value);
    setTo(lastDayOfMonth(new Date(`${v?.value}/1/${toYear}`)));
  };
  const handleToYearChange = ({ v }: { v: MonthPickerOption | null }) => {
    setToYear(v?.value);
    setTo(lastDayOfMonth(new Date(`${toMonth}/1/${v?.value}`)));
  };

  const currentYear = new Date().getFullYear().toString();

  return (
    <Stack direction="row">
      <div
        className={styles.MonthPicker}
        style={{ minWidth: width, width }}
        data-automation-id="MonthPicker"
      >
        <div className={styles.OuterGrid}>
          <div className={styles.CalendarIcon}>
            <CalendarTodayIcon sx={{ fontSize: iconSize }} />
          </div>
          <div className={styles.InnerGrid}>
            <Autocomplete
              id="from-month-autocomplete"
              className={styles.Autocomplete}
              value={getMonthValue(fromMonth || '1')}
              renderInput={(p) =>
                renderInput(p, t('MonthInputPlaceholder'), t('FromMonthLabelText'))
              }
              componentsProps={componentProps}
              isOptionEqualToValue={isOptionEqualToValue}
              options={monthList}
              getOptionDisabled={fromMonthDisabled}
              onChange={(e, v) => {
                handleFromMonthChange({ v: v as MonthPickerOption });
              }}
              autoHighlight
              data-automation-id="MonthPickerAutocompleteFromMonth"
            />
            <Autocomplete
              id="from-year-autocomplete"
              className={styles.Autocomplete}
              value={getYearValue(fromYear || currentYear)}
              renderInput={(p) => renderInput(p, t('YearInputPlaceholder'), t('FromYearLabelText'))}
              componentsProps={componentProps}
              isOptionEqualToValue={isOptionEqualToValue}
              options={yearList}
              onChange={(e, v) => {
                handleFromYearChange({ v: v as MonthPickerOption });
              }}
              getOptionDisabled={fromYearDisabled}
              autoHighlight
              data-automation-id="MonthPickerAutocompleteFromYear"
            />
          </div>
          <div className={styles.Icon}>
            <ArrowForwardIcon sx={{ fontSize: iconSize }} />
          </div>
          <div className={styles.InnerGrid}>
            <Autocomplete
              id="to-month-autocomplete"
              className={styles.Autocomplete}
              value={getMonthValue(toMonth || '1')}
              renderInput={(p) => renderInput(p, t('MonthInputPlaceholder'), t('ToMonthLabelText'))}
              componentsProps={componentProps}
              isOptionEqualToValue={isOptionEqualToValue}
              options={monthList}
              onChange={(e, v) => {
                handleToMonthChange({ v: v as MonthPickerOption });
              }}
              getOptionDisabled={toMonthDisabled}
              autoHighlight
              data-automation-id="MonthPickerAutocompleteToMonth"
            />
            <Autocomplete
              id="to-year-autocomplete"
              className={styles.Autocomplete}
              value={getYearValue(toYear || currentYear)}
              renderInput={(p) => renderInput(p, t('YearInputPlaceholder'), t('ToYearLabelText'))}
              componentsProps={componentProps}
              isOptionEqualToValue={isOptionEqualToValue}
              options={yearList}
              onChange={(e, v) => {
                handleToYearChange({ v: v as MonthPickerOption });
              }}
              getOptionDisabled={toYearDisabled}
              autoHighlight
              data-automation-id="MonthPickerAutocompleteToYear"
            />
          </div>
        </div>
      </div>

      <Tooltip
        title={
          applyButtonDisabled
            ? t('ApplyButtonDisabledHelperText', 'Change period to enable')
            : undefined
        }
      >
        {/* Reason for div here: https://mui.com/material-ui/react-tooltip/#disabled-elements */}
        <div>
          <Button
            onClick={onApply}
            disabled={applyButtonDisabled}
            data-automation-id="MonthPickerApplyButton"
          >
            {t('ApplyButtonText', 'Apply')}
          </Button>
        </div>
      </Tooltip>
    </Stack>
  );
};
