import { da } from 'date-fns/locale/da';
import { de } from 'date-fns/locale/de';
import { enGB as en } from 'date-fns/locale/en-GB';
import { fi } from 'date-fns/locale/fi';
import { nb } from 'date-fns/locale/nb';
import { nl } from 'date-fns/locale/nl';
import { ru } from 'date-fns/locale/ru';
import { sv } from 'date-fns/locale/sv';
import { fr } from 'date-fns/locale/fr';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';

const localeMap = {
  da,
  de,
  en,
  fi,
  nb,
  nl,
  ru,
  sv,
  fr,
};

export const useGetDateLocale = () => {
  const { currentLanguage, isSuccess } = useGetCurrentLanguage();

  if (isSuccess) {
    return currentLanguage ? localeMap[currentLanguage] : en;
  }

  return en;
};
